import { OfflineComment } from 'common/types'
import { DateTime } from 'luxon'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Textarea } from '../../components/ui/textarea'
import { set } from '../../firebaseMethods'

export const BatchEditOfflinesDialog = ({
  deferred,
  serials,
}: {
  deferred: Deferred<void>
  serials: string[]
}) => {
  const { source } = useContext(DataContext)

  const { handleSubmit, register, formState } = useForm<OfflineComment>({
    defaultValues: {
      comment: '',
      source: source,
    },
  })

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog
      title={<Title>Édition de {serials.length} appareils</Title>}
      onClose={handleClose}
    >
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(async ({ comment }) => {
          const now = DateTime.now().toMillis()

          const offlineComment: OfflineComment = {
            source,
            comment,
            timestamp: now,
          }

          await Promise.all(
            serials.map((serial) => {
              return set(`devicesOfflineComment/${serial}`, offlineComment)
            }),
          )

          deferred.resolve()
        })}
      >
        <div className="flex flex-col gap-2">
          <label>Commentaire surveillance des Hors-Lignes</label>
          <Textarea {...register('comment')} />
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            Annuler
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
